<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="MapIcon"
          size="21"
      />
      <strong>
        Térkép
      </strong>
    </template>
    <div>
      <map-editor
          ref="map-editor"
          :map-url="mapUrl"
          :markers="markers"
      />
    </div>
  </b-tab>
</template>

<script>
import {
  BTab,
} from 'bootstrap-vue'
import mapEditor from '@/views/pages/_components/_map/MapEditor'

export default {
  components: {
    BTab,
    mapEditor
  },
  props: {
    markers: {
      type: Array,
      required: true
    }
  },
  created() {
    this.initMap()
  },
  methods: {
    initMap(){
      this.$store.dispatch('fetchLatestReservationMap').then(response => {
        this.mapUrl = response.data.entity.mapUrl
      })
    }
  },
  data() {
    return {
      mapUrl: '',
    }
  },
}
</script>
