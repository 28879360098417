<template>
  <div>
    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card
          :title="componentTitle"
      >
        <div class="component"
        >
          <div class="calendar-legend">
            <div class="row">
              <div class="col-12 calendar-legend-wrapper">
                <p class="calendar-legend-title text-center">Naptárban használt jelölések</p>
              </div>

              <div class="col calendar-legend-wrapper">
                <span class="calendar-legend-circle special-day-rest-day"></span>
                <p>Pihenőnap</p>
              </div>

              <div class="col calendar-legend-wrapper">
                <span class="calendar-legend-circle event"></span>
                <p>Nem foglalható</p>
              </div>

              <div class="col calendar-legend-wrapper">
                <span class="calendar-legend-circle special-day-non-working-day"></span>
                <p>Munkaszüneti nap</p>
              </div>

              <div class="col calendar-legend-wrapper">
                <span class="calendar-legend-circle reservation-in-progress"></span>
                <p>Foglalás folyamatban (kérelem)</p>
              </div>

              <div class="col calendar-legend-wrapper">
                <span class="calendar-legend-circle approved-reservation"></span>
                <p>Foglalás (visszaigazolt)</p>
              </div>

              <div class="col calendar-legend-wrapper">
                <span class="calendar-legend-circle selected"></span>
                <p>Saját foglalás</p>
              </div>
            </div>

          </div>
          <Calendar
              ref="calendar"
              :data-source="dataSource"
              :year="currentYear"
              :min-date="minDate"
              :max-date="maxDate"
              :language="language"
              :render-style="style"
              :allow-overlap="allowOverlap"
              :enable-range-selection="enableRangeSelection"
              :display-week-number="displayWeekNumber"
              :display-disabled-data-source="false"
              :round-range-limits="roundRangeLimits"
              :always-half-day="alwaysHalfDay"
              :display-header="displayHeader"
              :disabled-days="disabledDays"
              :custom-data-source-renderer="customDataSourceRenderer"
              :custom-day-renderer="customDayRenderer"
              @select-range="selectRange"
              @mouse-on-day="mouseOnDay"
          />
          <b-popover
              v-for="(day, dayKey) in popoverData"
              :key="dayKey"
              :id="'popover-calendar-day-'+dayKey"
              :ref="'popover-calendar-day-'+dayKey"
              :target="'calendar-day-'+dayKey"
              triggers="hover focus"
              placement="top"
          >
            <template #title>{{ day.title }}</template>

            <span v-html="day.description"></span>
            <p v-show="day.showButton">
              <!-- TODO: create reservation with this details!!! -->
              <b-button @click="createReservation"
                        variant="primary">Foglalás
              </b-button>
              <b-button
                  variant="link" @click="resetData()">Mégsem
              </b-button>
            </p>
          </b-popover>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import Calendar from './Calendar/Calendar.vue';
import './Calendar/locales/hu';
import {BOverlay, BTooltip, VBPopover, BPopover, BCard, BButton} from 'bootstrap-vue'

export default {
  components: {
    Calendar,
    BOverlay,
    BTooltip,
    BPopover,
    BCard,
    BButton
  },
  directives: {
    'b-popover': VBPopover
  },
  props: {
    canCreateReservation: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      componentTitle: 'Általános horgásznaptár',
      currentYear: new Date().getFullYear(),
      minDateString: null,
      maxDateString: null,
      minNights: '',
      language: 'hu',
      style: 'custom',
      allowOverlap: true,
      enableRangeSelection: true,
      displayWeekNumber: false,
      roundRangeLimits: false,
      alwaysHalfDay: true,
      displayHeader: false,
      backendData: [],
      disabledDays: [],
      selectionData: [],
      popoverData: [],
      cssClassData: [],
      reservableData: [],
      dataSource: [],
      dateFrom: null,
      dateTo: null,
      selectedMarkerId: '',
      showPopupId: '',
      lastShownPopover: '',
      loading: false,
      showPopoverButtons: false,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.backendData = [];
      this.disabledDays = [];
      this.loading = true

      this.$store.dispatch('fetchCalendarDays', {selectedMarkerId: this.selectedMarkerId}).then(res => {

        this.minNights = res.data.minNights

        Object.keys(res.data['days']).forEach(key => {

          let startDate = res.data['days'][key]['startDate']
          let endDate = res.data['days'][key]['endDate']


          this.backendData.push({
            startDate: new Date(parseInt(startDate.year), parseInt(startDate.month) - 1, parseInt(startDate.day)),
            endDate: new Date(parseInt(endDate.year), parseInt(endDate.month) - 1, parseInt(endDate.day)),
            classList: res.data['days'][key].classList,
          })
        })
      }).catch(() => {
        //TODO: error handling
      }).finally(() => {

        this.$store.dispatch('fetchCalendarPopovers', {selectedMarkerId: this.selectedMarkerId}).then(res => {
          this.popoverData = res.data.popoverData
          this.cssClassData = res.data.cssClasses
          this.reservableData = res.data.reservable
        }).finally(() => {
          this.createDataSource()
          if (this.dateFrom !== '' && this.dateFrom !== null && this.dateTo !== '' && this.dateTo !== null) {
            let startDate = this.$helpers.getDate(this.dateFrom)
            let endDate = this.$helpers.getDate(this.dateTo)

            let popoverData = '<p><strong>' + this.$helpers.getDate(this.dateFrom).add(1, 'days').format('YYYY-MM-DD') + ' - ' + this.$helpers.getDate(this.dateTo).add(1, 'days').format('YYYY-MM-DD') + '</strong></p>';

            const oneDay = 1000 * 60 * 60 * 24;
            const fromStart = new Date(parseInt(startDate.format('YYYY')), 0, 0);
            const fromDiff = startDate - fromStart + (this.$helpers.getTimezoneOffset(fromStart) - this.$helpers.getTimezoneOffset(startDate)) * 60 * 1000;
            const fromNthDay = Math.floor(fromDiff / oneDay);
            const toStart = new Date(parseInt(endDate.format('YYYY')), 0, 0);
            const toDiff = endDate - toStart + (this.$helpers.getTimezoneOffset(toStart) - this.$helpers.getTimezoneOffset(endDate)) * 60 * 1000;
            const toNthDay = Math.floor(toDiff / oneDay);

            for (let i = fromNthDay; i <= toNthDay; i++) {
              this.popoverData[i + 1].title = 'Foglalás'
              this.popoverData[i + 1].description = popoverData
              this.popoverData[i + 1].showButton = true
            }
          }
        })
        this.loading = false
      })
    },
    createDataSource() {
      this.dataSource = this.backendData
      this.dataSource = this.dataSource.concat(this.selectionData)
      window.setTimeout(() => {
        this.$refs.calendar.updateCalendar(this.currentYear)
      }, 300)
    },
    refreshData(selectedMarkerId, selectedMarkerName, calendarData) {
      this.selectedMarkerId = selectedMarkerId
      if (selectedMarkerName !== '') {

        this.componentTitle = selectedMarkerName + ' horgásznaptára'
      } else {
        this.componentTitle = 'Általános horgásznaptár'
      }
      this.initData();
    },
    resetData() {
      this.dateFrom = null
      this.dateTo = null
      this.selectionData = []
      this.initData()
    },
    createReservation() {
      if (!this.canCreateReservation) {
        this.$helpers.showErrorToast('Kérlek előbb válassz horgászhelyet!')
        return false
      } else {
        this.$router.push({
          name: 'add-reservation',
          query: {dateFrom: this.dateFrom, dateTo: this.dateTo, selectedMarkerId: this.selectedMarkerId}
        })
      }
    },
    getData() {
      return {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
    },
    selectRange: function (e, fromBackend = false) {
      if (!fromBackend && !this.isSelectionValidByNumberOfNights(e.startDate, e.endDate)) {
        this.$helpers.showErrorToast('Minimum ' + this.minNights + ' éjszaka!')

        this.dateFrom = null
        this.dateTo = null
        return false;
      }

      if (!fromBackend && !this.isSelectionValidByEvents(e.startDate, e.endDate)) {
        this.$helpers.showErrorToast('A kijelölt időszak nem foglalható!')

        this.dateFrom = null
        this.dateTo = null
        return false;
      }

      this.dateFrom = e.startDate.toISOString().substring(0, 10);
      this.dateTo = e.endDate.toISOString().substring(0, 10);

      this.selectionData = [{
        startDate: e.startDate,
        endDate: e.endDate,
        classList: 'selected',
      }]

      let startDate = this.$helpers.getDate(this.dateFrom)
      let endDate = this.$helpers.getDate(this.dateTo)

      let popoverData = '<p><strong>' + this.$helpers.getDate(this.dateFrom).add(1, 'days').format('YYYY-MM-DD') + ' - ' + this.$helpers.getDate(this.dateTo).add(1, 'days').format('YYYY-MM-DD') + '</strong></p>';

      const oneDay = 1000 * 60 * 60 * 24;
      const fromStart = new Date(parseInt(startDate.format('YYYY')), 0, 0);
      const fromDiff = startDate - fromStart + (this.$helpers.getTimezoneOffset(fromStart) - this.$helpers.getTimezoneOffset(startDate)) * 60 * 1000;
      const fromNthDay = Math.floor(fromDiff / oneDay);
      const toStart = new Date(parseInt(endDate.format('YYYY')), 0, 0);
      const toDiff = endDate - toStart + (this.$helpers.getTimezoneOffset(toStart) - this.$helpers.getTimezoneOffset(endDate)) * 60 * 1000;
      const toNthDay = Math.floor(toDiff / oneDay);

      for (let i = fromNthDay; i <= toNthDay; i++) {
        this.popoverData[i + 1].title = 'Foglalás'
        this.popoverData[i + 1].description = popoverData
        this.popoverData[i + 1].showButton = true
      }

      /*let showPopover = fromNthDay+(toNthDay-fromNthDay/2)
      this.$root.$emit('bv::show::popover', 'popover-' + showPopover)
      this.lastShownPopover = showPopover*/

      this.show = true
      this.createDataSource()

      this.$emit('calendar-refreshed', {dateFrom: this.dateFrom, dateTo: this.dateTo})
    },
    isSelectionValidByNumberOfNights(fromDate, toDate) {
      let differenceInTime = toDate.getTime() - fromDate.getTime();

      let differenceInNights = differenceInTime / (1000 * 3600 * 24);

      return differenceInNights >= this.minNights;
    },
    isSelectionValidByEvents(fromDate, toDate) {
      let oneDay = 1000 * 60 * 60 * 24;
      let fromStart = new Date(fromDate.getFullYear(), 0, 0);
      let fromDiff = (fromDate - fromStart) + ((fromStart.getTimezoneOffset() - fromDate.getTimezoneOffset()) * 60 * 1000);
      let fromNthDay = Math.floor(fromDiff / oneDay);

      let toStart = new Date(toDate.getFullYear(), 0, 0);
      let toDiff = (toDate - toStart) + ((toStart.getTimezoneOffset() - toDate.getTimezoneOffset()) * 60 * 1000);
      let toNthDay = Math.floor(toDiff / oneDay);

      let reservable = true

      Object.keys(this.reservableData).forEach(key => {
        if (key >= (fromNthDay) && key <= (toNthDay)) {
          if (!this.reservableData[key]) {
            reservable = false;
          }
        }
      });
      return reservable;
    },
    mouseOnDay: function (event) {
      if (this.lastShownPopover !== event.element.id) {
        this.$root.$emit('bv::hide::popover', 'popover-' + this.lastShownPopover)
        this.$root.$emit('bv::show::popover', 'popover-' + event.element.id)
        this.lastShownPopover = event.element.id
      }
    },
    mouseOutDay() {
      // TODO: ez így szar
      //this.$root.$emit('bv::hide::popover', 'popover-' + this.lastShownPopover)
    },
    customDataSourceRenderer: function (element, date, events) {
      this.loading = true;
      let classList = [];

      let start = new Date(date.getFullYear(), 0, 0);
      let diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
      let oneDay = 1000 * 60 * 60 * 24;
      let nthDay = Math.floor(diff / oneDay);


      Object.keys(events).forEach(key => {
        let classParts = events[key].classList.split(" ");
        Object.keys(classParts).forEach(classKey => {
          if (!classList.includes(classParts[classKey])) {
            classList.push(classParts[classKey])
          }
        })

        if (events[key].classList === 'selected') {
          if (!classList.includes('selected')) {
            classList.push('selected')
          }
        }
      })

      Object.keys(this.cssClassData).forEach(key => {
        if (key == nthDay) {
          Object.keys(this.cssClassData[key]).forEach(classKey => {

            const cssClass = this.cssClassData[key][classKey];

            if (!classList.includes(cssClass)) {
              classList.push(cssClass);
            }
          })
        }
      });

      element.parentNode.classList = classList.join(' ')
      this.loading = false;
    },
    customDayRenderer(element, date) {
      let start = new Date(date.getFullYear(), 0, 0);
      let diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
      let oneDay = 1000 * 60 * 60 * 24;
      let nthDay = Math.floor(diff / oneDay);

      element.parentNode.setAttribute('id', 'calendar-day-' + nthDay)
    }
  },
  computed: {
    minDate: function () {
      return this.minDateString != null ? new Date(this.minDateString) : null;
    },
    maxDate: function () {
      return this.maxDateString != null ? new Date(this.maxDateString) : null;
    }
  }
}
</script>

<style>
.special-day-workday {
  background: none;
  color: #212529;
}

.special-day-non-working-day {
  background: #888888;
  color: #212529;
}

.special-day-rest-day {
  background: #E5E5E5;
  color: #212529;
}

.event {
  background: #D6D6D6;
  color: #212529;
  cursor: default;
}

.event.event-started {
  background-color: #D6D6D6;
  color: #212529;
}

.event.event-started.day-half {
  background: linear-gradient(-45deg, #D6D6D6, #D6D6D6 49%, transparent 51%, transparent) !important;
  color: #212529;
}

.event.event-ended.day-half {
  background: linear-gradient(135deg, #D6D6D6, #D6D6D6 49%, transparent 51%, transparent) !important;
  color: #212529;
}

.event.event-started.event-ended.day-half {
  background: #D6D6D6 !important;
  color: #212529;
}

.reservation.reservation-in-progress {
  background: #A5E4FF;
  color: #212529;
  cursor: default;
}

.reservation.reservation-in-progress.own-reservation {

}

.reservation.reservation-in-progress.reservation-started.day-half {
  background: linear-gradient(-45deg, #A5E4FF, #A5E4FF 49%, transparent 51%, transparent) !important;
}

.reservation.reservation-in-progress.reservation-ended.day-half {
  background: linear-gradient(135deg, #A5E4FF, #A5E4FF 49%, transparent 51%, transparent) !important;
}

.reservation.reservation-approved {
  background: #7FC5E9;
  color: #212529;
  cursor: default;
}

.reservation.reservation-approved.reservation-started.day-half {
  background: linear-gradient(-45deg, #7FC5E9, #7FC5E9 49%, transparent 51%, transparent) !important;
}

.reservation.reservation-approved.reservation-ended.day-half {
  background: linear-gradient(135deg, #7FC5E9, #7FC5E9 49%, transparent 51%, transparent) !important;
}

.disabled-day {
  color: #dddddd;
  background: #ffffff;
  cursor: default !important;
}


table.month td.day.disabled-day .day-content:hover,
table.month td.day.event .day-content:hover,
table.month td.day.reservation-in-progress .day-content:hover,
table.month td.day.approved-reservation .day-content:hover,
table.month td.day.my-reservation .day-content:hover {
  background: none !important;
}

.selected, .my-reservation {
  background: red;
  color: #212529;
}

.my-reservation {
  cursor: default;
}


.selected.selected-start.selected-half, .my-reservation.day-start.day-half {
  background: linear-gradient(-45deg, red, red 49%, transparent 51%, transparent) !important;
}

.selected.selected-end.selected-half, .my-reservation.day-end.day-half {
  background: linear-gradient(135deg, red, red 49%, transparent 51%, transparent) !important;
}

.day {
  position: relative;
}

.day:before {
  content: '';

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;
}


.selected.selected-start.selected-half, .my-reservation.day-start.day-half {
  background: linear-gradient(-45deg, red, red 49%, transparent 51%, transparent) !important;
}

.day.reservation.reservation-approved.reservation-ended.day-half.own-reservation.reservation-started,
.day.reservation.reservation-approved.own-reservation.reservation-started.day-half.reservation-in-progress.reservation-ended {
  background: linear-gradient(135deg, #7FC5EA, #7FC5EA 49%, transparent 51%, transparent) !important;
}

.day.reservation.reservation-approved.reservation-started.day-half.reservation-in-progress.reservation-ended {
  background: linear-gradient(-45deg, #7FC5EA, #7FC5EA 49%, transparent 51%, transparent) !important;
}

.special-day-non-working-day.selected.selected-end.selected-half:before,
.special-day-non-working-day.reservation.reservation-in-progress.reservation-ended.day-half:before {
  background-color: #888888;
}

.special-day-rest-day.selected.selected-end.selected-half:before,
.special-day-rest-day.reservation.reservation-in-progress.own-reservation.reservation-ended.day-half:before {
  background-color: #e5e5e5;
}

.selected.selected-end.selected-half.day.reservation.reservation-approved.own-reservation.reservation-started.day-half:before,
.special-day-rest-day.selected.selected-start.selected-half.day.reservation.reservation-approved.own-reservation.reservation-ended.day-half:before,
.selected.selected-start.selected-half.day.reservation.reservation-in-progress.own-reservation.reservation-ended.day-half:before,
.selected.selected-start.selected-half.day.reservation.reservation-approved.reservation-ended.day-half:before,
.selected.selected-start.selected-half.day.reservation.reservation-in-progress.reservation-ended.day-half:before,
.selected.selected-end.selected-half.day.reservation.reservation-approved.reservation-started.day-half:before {
  background-color: red;
}

.special-day-rest-day.day.reservation.reservation-approved.own-reservation.reservation-ended.day-half.reservation-started:before,
.special-day-rest-day.day.reservation.reservation-approved.reservation-ended.day-half.reservation-started:before {
  background-color: #7FC5E9;
}

.selected.selected-end.selected-half.day.event.event-started.day-half:before,
.selected.selected-start.selected-half.day.event.event-ended.day-half:before,
.day.event.event-ended.day-half.reservation.reservation-in-progress.own-reservation.reservation-started:before,
.day.event.event-ended.day-half.reservation.reservation-approved.own-reservation.reservation-started:before,
.day.event.event-ended.day-half.reservation.reservation-approved.reservation-started:before {
  background-color: #D6D6D6;
}

.day.reservation.reservation-approved.reservation-started.day-half.reservation-in-progress.reservation-ended:before,
.special-day-non-working-day.day.reservation.reservation-in-progress.reservation-ended.day-half.reservation-started:before {
  background-color: #A5E4FF;
}

/* Saját jóvágyaott */
.reservation.reservation-approved.reservation-ended.day-half.own-reservation {
  background: linear-gradient(135deg, #fc2222, #fc2222 49%, transparent 51%, transparent) !important;
}

/* Saját jóvágyaott */
.reservation.reservation-approved.own-reservation,
.special-day-non-working-day.day.reservation.reservation-approved.own-reservation,
.day.reservation.reservation-approved.reservation-ended.day-half.own-reservation.reservation-started,
.day.reservation.reservation-approved.reservation-ended.day-half.own-reservation.reservation-started:before,
.special-day-rest-day.day.reservation.reservation-approved.own-reservation.reservation-ended.day-half.reservation-in-progress.reservation-started:before {
  background-color: #fc2222 !important;
}


/* Saját kérelem */
.reservation.reservation-in-progress.reservation-started.own-reservation.day-half {
  background: linear-gradient(-45deg, #fc4747, #fc4747 49%, transparent 51%, transparent) !important;
}

/* Saját kérelem */
.special-day-rest-day.day.reservation.reservation-approved.own-reservation.reservation-ended.day-half.reservation-in-progress.reservation-started,
.reservation.reservation-in-progress.reservation-ended.own-reservation.day-half {
  background: linear-gradient(135deg, #fc4747, #fc4747 49%, transparent 51%, transparent) !important;
}

/* Saját kérelem */
.day.reservation.reservation-approved.own-reservation.reservation-started.day-half.reservation-in-progress.reservation-ended:before,
.reservation.reservation-in-progress.own-reservation,
.special-day-rest-day.day.reservation.reservation-in-progress.own-reservation.reservation-ended.day-half.reservation-started:before,
.special-day-non-working-day.reservation.reservation-in-progress.own-reservation.reservation-ended.day-half:before {
  background-color: #fc4747;
}

.selected:nth-of-type(1) {
  background-color: red;
}
</style>

<style lang="scss">
.map-legend {
  padding-top: 50px;
}

.map-legend-item {
  display: flex;
  align-items: center;
  min-height: 36px;
  font-size: 15px;
  line-height: calc(23 / 15);
  text-align: left;
  margin-bottom: 10px;
}

.map-legend-icon {
  margin-right: 15px;
}

.instructions {
  padding-top: 30px;
}

.instructions-inner {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: border-box;
  box-shadow: 0 28px 30px rgba(0, 0, 0, 0.06);
  padding: 10px 23px;
}

.instructions-inner img {
  margin-right: 47px;
}

.instructions-inner p {
  font-size: 18px;
  font-weight: 600;
  color: #22455D;
  line-height: calc(21 / 18);
  margin-bottom: 0;
}

.component {
  padding-top: 78px;
}

.component-title {
  margin-bottom: 5px;
}

.component-subtitle {
  color: #E99846;
  margin-bottom: 40px;
}

.calendar-legend-wrapper {
  display: flex;
  align-items: center;
}

.calendar-legend-wrapper.col {
  margin-bottom: 15px;
}

.calendar-legend-wrapper.col-12 {
  justify-content: center;
}

.calendar-legend-wrapper p {
  font-size: 15px;
  line-height: calc(23 / 15);
  white-space: nowrap;
  margin-bottom: 0;
}

.calendar-legend-wrapper p.calendar-legend-title {
  font-size: 18px;
  font-weight: 600;
  color: #204359;
  line-height: calc(27 / 18);
  margin-bottom: 10px;
}

.calendar-legend-circle {
  display: block;
  max-width: 30px;
  flex-basis: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-right: 14px;
}

.calendar-legend-circle.selected {
  border-color: #F28D3A;
}

.calendar-legend-circle.approved-reservation {
  background-color: #7FC5E9;
  border-color: #7FC5E9;
}

.calendar-legend-circle.reservation-in-progress {
  background-color: #A5E4FF;
  border-color: #A5E4FF;
}

.calendar-legend-circle.special-day-non-working-day {
  border-color: #888888;
}

.calendar-legend-circle.event {
  border-color: #D6D6D6;
}

.calendar-legend-circle.special-day-rest-day {
  border-color: #E5E5E5;
}

.calendar-legend-circle.own-reservation-in-progress {
  background-color: #fc4747;
  border-color: #fc4747;
}

.calendar-legend-circle.own-reservation-approved {
  background-color: #fc2222;
  border-color: #fc2222;
}

@media screen and (max-width: 1440px) {
  .col.calendar-legend-wrapper {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media screen and (max-width: 1366px) {
  .instructions-inner img {
    width: 90px;
    height: auto;
  }
  .instructions-inner p {
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .instruction-wrapper:not(:last-child) {
    margin-bottom: 30px;
  }
  .instruction-inner img {
    width: 70px;
  }
  .instructions-inner img {
    width: 70px;
    margin-right: 20px;
  }
  .col.calendar-legend-wrapper {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media screen and (max-width: 575px) {
  .col.calendar-legend-wrapper {
    max-width: 100%;
    flex-basis: 100%;
  }
}
</style>
