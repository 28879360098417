<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                  label="Fotóalbum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="fotóalbum"
                    rules="required"
                    vid="gallery"
                >
                  <v-select
                      v-model="formData.gallery"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="galleryOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label="Árazás típus"
              >
                <validation-provider
                    #default="{ errors }"
                    name="árazás típus"
                    rules="required"
                    vid="pricingType"
                >
                  <v-select
                      v-model="formData.pricingType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="pricingTypeOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label="Rekordlista megfeleltetés"
              >
                <validation-provider
                    #default="{ errors }"
                    name="rekordlista megfeleltetés"
                    rules="required"
                    vid="location"
                >
                  <v-select
                      v-model="formData.location"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="sortedLocationOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Név"
              >
                <validation-provider
                    #default="{ errors }"
                    name="név"
                    rules="required"
                    vid="name"
                >
                  <b-form-input
                      v-model="formData.name"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Kód"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kód"
                    rules="required"
                    vid="code"
                >
                  <b-form-input
                      v-model="formData.code"
                      :state="errors.length > 0 ? false:null"
                      @input="refreshMapMarker"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Minimálisan fizetendő díj"
                  description="A horgászhelyre történő foglalás esetén ennyit akkor is ki kell fizetni, ha paraméterek szerint számolt díj ennél kevesebb"
              >
                <validation-provider
                    #default="{ errors }"
                    name="minimálisan fizetendő díj"
                    rules="required"
                    vid="minimumFee"
                >
                  <b-form-input
                      v-model="formData.minimumFee"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Min. felnőtt horgász száma"
                  description="A hely foglalásához szükséges minimális felnőtt horgászok száma"
              >
                <validation-provider
                    #default="{ errors }"
                    name="min. felnőtt horgász száma"
                    rules="required"
                    vid="minAdult"
                >
                  <b-form-input
                      v-model="formData.minAdult"
                      type="number"
                      min="1"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Min. felnőtt és ifi horgászok száma"
                  description="A hely foglalásához szükséges minimális felnőtt és ifi horgászok együttes száma (felnőtt+ifi)"
              >
                <validation-provider
                    #default="{ errors }"
                    name="min. felnőtt és ifi horgászok száma"
                    rules="required"
                    vid="minAdultYoungster"
                >
                  <b-form-input
                      v-model="formData.minAdultYoungster"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Max. felnőtt és ifi horgászok száma"
                  description="A hely kapacitásának megfelelő, engedélyezhető maximális horgászlétszám (felnőtt+ifi)"
              >
                <validation-provider
                    #default="{ errors }"
                    name="max. felnőtt és ifi horgászok száma"
                    rules="required"
                    vid="maxAdultYoungster"
                >
                  <b-form-input
                      v-model="formData.maxAdultYoungster"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Engedélyezhető egyéb létszám"
                  description="Engedélyezhető egyéb létszám (gyermek + kísérő)"
              >
                <validation-provider
                    #default="{ errors }"
                    name="engedélyezhető egyéb létszám"
                    rules="required"
                    vid="maxChildAttendant"
                >
                  <b-form-input
                      v-model="formData.maxChildAttendant"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Egyéb információ"
                  description="Egyéb információ (infobuborék)"
              >
                <validation-provider
                    #default="{ errors }"
                    name="egyéb információ"
                    vid="moreInfo"
                >
                  <editor
                      v-model="formData.moreInfo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Megjegyzés"
              >
                <validation-provider
                    #default="{ errors }"
                    name="megjegyzés"
                    vid="comment"
                >
                  <editor
                      v-model="formData.comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="csomagszállításhoz szilárd burkolatú bejáratú út biztosított"
                  vid="hasPavedRoad"
              >
                <b-form-checkbox
                    v-model="formData.hasPavedRoad"
                    :state="errors.length > 0 ? false:null"
                >
                  Csomagszállításhoz szilárd burkolatú bejáratú út biztosított
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="külön fizetendő parkolási díj"
                  vid="hasParkingFee"
              >
                <b-form-checkbox
                    v-model="formData.hasParkingFee"
                    :state="errors.length > 0 ? false:null"
                >
                  Külön fizetendő parkolási díj
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="H/M zuhanyzási lehetőség, vízöblítéses WC biztosított"
                  vid="hasShower"
              >
                <b-form-checkbox
                    v-model="formData.hasShower"
                    :state="errors.length > 0 ? false:null"
                >
                  H/M zuhanyzási lehetőség, vízöblítéses WC biztosított
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="kiépített esőbeálló asztallal és padokkal (filagória) biztosított"
                  vid="hasPavilion"
              >
                <b-form-checkbox
                    v-model="formData.hasPavilion"
                    :state="errors.length > 0 ? false:null"
                >
                  Kiépített esőbeálló asztallal és padokkal (filagória) biztosított
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="kiépített tűzrakó hely biztosított"
                  vid="hasFireplace"
              >
                <b-form-checkbox
                    v-model="formData.hasFireplace"
                    :state="errors.length > 0 ? false:null"
                >
                  Kiépített tűzrakó hely biztosított
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="közvilágítás biztosított"
                  vid="hasStreetlight"
              >
                <b-form-checkbox
                    v-model="formData.hasStreetlight"
                    :state="errors.length > 0 ? false:null"
                >
                  Közvilágítás biztosított
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="Áramfelvételi lehetőség külön térítés mellett"
                  vid="hasPowerConsumption"
              >
                <b-form-checkbox
                    v-model="formData.hasPowerConsumption"
                    :state="errors.length > 0 ? false:null"
                >
                  Áramfelvételi lehetőség külön térítés mellett
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="hagyományos horgászati módszer választható"
                  vid="canUseGeneralFishingMethod"
              >
                <b-form-checkbox
                    v-model="formData.canUseGeneralFishingMethod"
                    :state="errors.length > 0 ? false:null"
                >
                  Hagyományos horgászati módszer választható
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="bojlis módszernél csónakhasználat térítésmentes lehetősége"
                  vid="canUseBoat"
              >
                <b-form-checkbox
                    v-model="formData.canUseBoat"
                    :state="errors.length > 0 ? false:null"
                >
                  Bojlis módszernél csónakhasználat térítésmentes lehetősége
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="lakókocsi, lakóbusz a hely közelében elhelyezhető"
                  vid="canUseCaravan"
              >
                <b-form-checkbox
                    v-model="formData.canUseCaravan"
                    :state="errors.length > 0 ? false:null"
                >
                  Lakókocsi, lakóbusz a hely közelében elhelyezhető
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="teljes sátor használható"
                  vid="canUseTentFull"
              >
                <b-form-checkbox
                    v-model="formData.canUseTentFull"
                    :state="errors.length > 0 ? false:null"
                >
                  Teljes sátor használható
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="csak félsátor használata engedélyezett"
                  vid="canUseTentHalf"
              >
                <b-form-checkbox
                    v-model="formData.canUseTentHalf"
                    :state="errors.length > 0 ? false:null"
                >
                  Csak félsátor használata engedélyezett
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                  #default="{ errors }"
                  name="eseti, rejtett horgászhely"
                  vid="isHidden"
              >
                <b-form-checkbox
                    v-model="formData.isHidden"
                    :state="errors.length > 0 ? false:null"
                >
                  Eseti, rejtett horgászhely
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
            :markers="markers"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BLink, BOverlay,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import editor from '@/views/pages/_components/editor.vue'
import tabs from '@/views/pages/_components/_tabs/_reservationFishingSpot/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BLink,
    BOverlay,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
    editor
  },
  data() {
    return {
      role: 'reservation',
      tabsLoading: false,
      formData: {
        id: '',
        gallery: '',
        pricingType: '',
        location: '',
        name: '',
        code: '',
        minimumFee: '',
        minAdult: 1,
        minAdultYoungster: 2,
        maxAdultYoungster: '',
        maxChildAttendant: '',
        moreInfo: '',
        comment: '',
        mapX: 0,
        mapY: 0,
        /* Checkboxes */
        hasPavedRoad: false,
        hasParkingFee: false,
        hasShower: false,
        hasPavilion: false,
        hasFireplace: false,
        hasStreetlight: false,
        hasPowerConsumption: false,
        canUseGeneralFishingMethod: false,
        canUseBoat: false,
        canUseCaravan: false,
        canUseTentFull: false,
        canUseTentHalf: false,
        isHidden: false,
      },
      markers: [],
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      galleryOptions: [],
      pricingTypeOptions: [],
      locationOptions: [],
      required,
    }
  },
  created() {
    this.initEntity()
  },
  computed: {
    sortedLocationOptions() {
      return this.locationOptions.slice().sort(function(a, b){
        return a.title.localeCompare(b.title)
      });
    }
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchReservationFishingSpot', this.$route.params.id).then(response => {
        this.processEntity(response.data.entity, onlyTabs)
      })
    },
    processEntity(entity, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {

        this.$store.dispatch('fetchGalleriesForSelect').then(gaResponse => {
          this.galleryOptions = gaResponse

          if (this.galleryOptions.length) {
            Object.keys(gaResponse).forEach(key => {
              if (gaResponse[key].value === parseInt(entity.gallery_id)) {
                this.formData.gallery = gaResponse[key]
              }
            })
          }
        })

        this.$store.dispatch('fetchPricingTypesForSelect').then(ptResponse => {
          this.pricingTypeOptions = ptResponse

          if (this.pricingTypeOptions.length) {
            Object.keys(ptResponse).forEach(key => {
              if (ptResponse[key].value === parseInt(entity.pricing_type_id)) {
                this.formData.pricingType = ptResponse[key]
              }
            })
          }
        })

        this.$store.dispatch('fetchLocationsForSelect').then(loResponse => {
          this.locationOptions = loResponse

          if (this.locationOptions.length) {
            Object.keys(loResponse).forEach(key => {
              if (loResponse[key].value === parseInt(entity.location_id)) {
                this.formData.location = loResponse[key]
              }
            })
          }
        })

        this.formData.id = this.$route.params.id
        this.formData.name = entity.name
        this.formData.code = entity.code
        this.formData.minimumFee = parseInt(entity.minimum_fee)
        this.formData.minAdult = entity.min_adult
        this.formData.minAdultYoungster = entity.min_adult_youngster
        this.formData.maxAdultYoungster = entity.max_adult_youngster
        this.formData.maxChildAttendant = entity.max_child_attendant
        this.formData.moreInfo = entity.more_info
        this.formData.comment = entity.comment
        this.formData.mapX = entity.map_x
        this.formData.mapY = entity.map_y
        this.formData.hasPavedRoad = entity.has_paved_road
        this.formData.hasParkingFee = entity.has_parking_fee
        this.formData.hasShower = entity.has_shower
        this.formData.hasPavilion = entity.has_pavilion
        this.formData.hasFireplace = entity.has_fireplace
        this.formData.hasStreetlight = entity.has_streetlight
        this.formData.hasPowerConsumption = entity.has_power_consumption
        this.formData.canUseGeneralFishingMethod = entity.can_use_general_fishing_method
        this.formData.canUseBoat = entity.can_use_boat
        this.formData.canUseCaravan = entity.can_use_caravan
        this.formData.canUseTentFull = entity.can_use_tent_full
        this.formData.canUseTentHalf = entity.can_use_tent_half
        this.formData.isHidden = entity.is_hidden
      }

      this.markers.push({
        id: 1,
        editable: true,
        position: {
          x: this.formData.mapX,
          y: this.formData.mapY,
        },
        label: this.formData.code,
        slug: this.$helpers.slug(this.formData.code),
      })

      this.$refs.tabs.data = this.markers

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    submitForm() {
      this.$refs.tabs.refreshMapMarkers();

      let mapEditorData = this.$refs.tabs.refreshMapMarkers()
      let marker = mapEditorData[0]

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.gallery = this.formData.gallery.value
          fd.pricingType = this.formData.pricingType.value
          fd.location = this.formData.location.value
          fd.mapX = marker.position.x
          fd.mapY = marker.position.y

          this.$store.dispatch('updateReservationFishingSpot', fd).then(async response => {
            this.processEntity(response.data.entity)
            this.$helpers.showSuccessToast()
            this.initEntity()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    refreshMapMarker() {
      this.markers[0].label = this.formData.code
      this.markers[0].slug = this.$helpers.slug(this.formData.code)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
