<template>
    <svg class="mt-3 mb-3" ref="map" :width="map.width" :height="map.height">
      <image
          :href="mapUrl"
          :width="map.width"
          :height="map.height"
      />
      <filter id="PATH_MARKER_WRAPPER">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur-16"/>
        <feFlood flood-opacity="0.161" result="color-9"/>
        <feComposite operator="out" in="SourceGraphic" in2="blur-16"/>
        <feComposite operator="in" in="color-9"/>
        <feComposite operator="in" in2="SourceGraphic"/>
      </filter>
        <g
            v-for="marker in markers"
            :key="marker.id"
            :id="'Group_' + marker.id" :data-name="'Group ' + marker.id" :transform="'translate('+marker.position.x+' '+marker.position.y+') ' + 'scale('+map.multiplier+')'"
            :class="marker.editable ? 'cursor-move' : ''"
            @mousedown="drag(marker, $event)"
            @mouseup="drop"
            @touchstart="drag(marker, $event)"
            @touchend="drop"
        >
          <g data-type="innerShadowGroup">
            <path :id="'Path_' + marker.slug" :data-name="'Path ' + marker.slug" d="M16.783,0A16.783,16.783,0,0,1,33.567,16.783a17.41,17.41,0,0,1-.579,4.388A16.785,16.785,0,1,1,16.783,0Z" transform="translate(1.097 1.097)" fill="#f38e3a"/>
            <g transform="matrix(1, 0, 0, 1, -780.27, -543.35)" filter="url(#PATH_MARKER_WRAPPER)">
              <path :id="'Path_' + marker.slug + '_1'" :data-name="'Path ' + marker.slug + '_1'" d="M16.783,0A16.783,16.783,0,0,1,33.567,16.783a17.41,17.41,0,0,1-.579,4.388A16.785,16.785,0,1,1,16.783,0Z" transform="translate(781.37 544.45)" fill="#fff"/>
            </g>
          </g>
          <path :id="'Path_' + marker.slug + '_2'" :data-name="'Path ' + marker.slug + '_2'" d="M259.843,637.367a17.881,17.881,0,1,1,17.881-17.881A17.9,17.9,0,0,1,259.843,637.367Zm0-33.567a15.686,15.686,0,1,0,15.686,15.686A15.7,15.7,0,0,0,259.843,603.8Z" transform="translate(-241.962 -601.605)" fill="#faaa68"/>
          <text :id="'Path_' + marker.slug + '_t'" :data-name="'Path ' + marker.slug + '_t'" transform="matrix(1, -0.01, 0.01, 1, 9, 22)" fill="#fff" :font-size="map.marker.fontSize" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">{{ marker.label }}</tspan></text>
        </g>
    </svg>
</template>

<script>

export default {
  props: {
    mapUrl: {
      type: String,
      required: true,
    },
    markers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isDrawing: false,
      selectedElement: null,
      map: {
        width: 1300,
        height: 750,
        reference: {
          width: 1538.175,
          height: 897.623,
        },
        // osztó szám
        multiplier: 1,
        // marker default size for reference
        marker: {
          width: 34,
          height: 34,
          fontSize: 12,
        }
      },
    }
  },
  created() {
    this.isDrawing = false
    this.selectedElement = null
    // calculate
    this.map.multiplier = this.map.width / this.map.reference.width
  },
  methods: {
    drag(element) {
      if (element.hasOwnProperty('editable') && !element.editable) return

      this.isDrawing = true
      this.selectedElement = element
      this.$refs.map.addEventListener('mousemove', this.move)
    },
    drop() {
      this.isDrawing = false
      this.selectedElement = null
      this.$refs.map.removeEventListener('mousemove', this.move)
    },
    move({offsetX, offsetY}) {
      if (this.selectedElement.hasOwnProperty('editable') && !this.selectedElement.editable) return

      if (this.isDrawing) {
        const positionX = offsetX - this.map.marker.width / 2
        const positionY = offsetY - this.map.marker.height / 2

        // firefox fix
        if (positionX < 0 || positionY < 0) {
          return
        }

        this.selectedElement.position.x = positionX
        this.selectedElement.position.y = positionY
      }
    },
    getData() {
      return {
        markers: this.markers,
        map: this.map
      }
    }
  }
}
</script>
