<template>
    <b-tab
            lazy
    >
        <template #title>
            <feather-icon
                    icon="ListIcon"
                    size="21"
            />
            <strong>
                Napló
            </strong>
        </template>
        <!-- table -->
        <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
        >
            <div slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                    {{ $t('table.list.empty') }}
                </div>
            </div>
            <template
                    slot="table-row"
                    slot-scope="props"
            >

                <!-- Column: Name -->
                <span
                        v-if="props.column.field === 'name'"
                        class="text-nowrap"
                >
                <span class="text-nowrap">{{ props.row.name }}</span>
              </span>
                <span
                        v-else-if="props.column.field === 'changes'"
                        class="text-nowrap"
                >
                <span class="text-nowrap" v-html="props.row.changes"></span>
              </span>

                <!-- Column: Common -->
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
                    slot="pagination-bottom"
                    slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                        <b-form-select
                                v-model="pageLength"
                                :options="pages"
                                class="mx-1"
                                @change="handlePageChange"
                        />
                        <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                    </div>
                    <div>
                        <b-pagination
                                :value="1"
                                :total-rows="dataFromServer.total"
                                :per-page="dataFromServer.per_page"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @change="handleChangePage"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-tab>
</template>

<script>
import {
    BTab, BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'

export default {
    components: {
        BTab,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    props: {
        entityId: {
            type: Number,
            required: false
        },
        entityType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            role: 'log',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: this.$t('form.name'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$t('form.date'),
                    field: 'created_at',
                    sortable: false,
                    formatFn: this.$formatters.formatLaravelTimestampToDateTime,
                },
                {
                    label: this.$t('form.event'),
                    field: 'event',
                    sortable: false,
                },
                {
                    label: this.$t('form.description'),
                    field: 'changes',
                    sortable: false,
                },
            ],
            rows: [],
            page: 1,
            dataFromServer: [],
            sortBy: {
                field: 'id',
                type: 'desc',
            },
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        this.initData()
    },
    methods: {
        initData() {
            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: {
                    model_id: this.entityId,
                    model_type: this.entityType,
                },
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchLogs', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            })
        },
        handleChangePage(page) {
            this.page = page
            this.initData()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initData()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.nested {
    list-style-type: none;
    margin: 0 0 0 20px;
    padding: 0;
}

.changesList {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 10px;
    background-color: transparent;
    color: #18171B;
    line-height: 1.2em;
    font: 12px Menlo, Monaco, Consolas, monospace;
}

.changesList .sf-dump-key {
    font-weight: 900;
}

.changesList .sf-dump-diff-minus {
    background: #FFDBDB;
    color: #18171B;
}

.changesList .sf-dump-diff-plus {
    background: #AEFDCD;
    color: #18171B;
}

.changesList .sf-dump-num {
    font-weight: bold;
    color: #1299DA;
}

.changesList .sf-dump-note {
    color: #1299DA;
}

.changesList .sf-dump-const {
    font-weight: bold;
}

.changesList .sf-dump-arrow {
    margin: 0 5px;
}
</style>
