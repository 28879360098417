<template>
  <b-button
      variant="outline-secondary"
      type="button"
      class="float-right"
      @click="cancelAndGotoPreviuosPage"
  >
    Mégsem
  </b-button>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: "cancelAndGOTOPreviousPage",
  components: {
    BRow,
    BCol,
    BButton,
  },
  methods: {
    cancelAndGotoPreviuosPage() {
      this.$bvModal
          .msgBoxConfirm('Biztos megszakítod a folyamatot és visszalépsz az előző oldalra?', {
            title: '',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Igen',
            cancelTitle: 'Nem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$router.go(-1)
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
