<template>
  <b-modal
      :id="getModalId"
      size="lg"
      hide-footer
      @show="initModal"
  >
    <div class="d-block">
      <div class="row">
        <div class="col">
          <b-form-textarea
              v-model="html"
              rows="10"
          />
        </div>
      </div>
    </div>
    <b-button class="mt-1 float-right" @click="$bvModal.hide(getModalId)">
      Bezárás
    </b-button>
    <b-button
        class="mt-1 mr-1 float-right"
        variant="primary"
        @click="saveHtml">
      Mentés
    </b-button>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BForm, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BForm,
    BFormTextarea,
    BButton,
  },
  computed: {
    getModalId() {
      return 'bv-modal-article-html-editor-' + this.modalId
    }
  },
  props: {
    modalId: {
      type: Number,
      required: true
    },
    rawHtml: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      html: '',
    }
  },
  methods: {
    initModal() {
      this.html = this.rawHtml
    },
    saveHtml() {
      this.$emit('save')
      this.$bvModal.hide(this.getModalId)
    },
    getHtml() {
      return this.html
    }
  }
}
</script>
