<template>
  <b-tabs content-class="mt-3">
    <map-tab
      ref="tabMap"
      :markers="markers"
    />
    <calendar-tab
      :fishing-spot-id="entityId"
    />
    <log-tab
      ref="tabLogTable"
      :entity-id="entityId"
      entity-type="reservation-fishing-spot"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import mapTab from '@/views/pages/_components/_tab/_reservationFishingSpot/_update/_map.vue'
import calendarTab from '@/views/pages/_components/_tab/_reservationFishingSpot/_update/_calendar.vue'
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'

export default {
  components: {
    BTabs,
    mapTab,
    calendarTab,
    logTab,
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    markers: {
      type: Array,
      required: true
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    },
    refreshLog() {
      this.$refs.tabLogTable.initData()
    },
    refreshMapMarkers(){
      return this.$refs.tabMap.markers;
    }
  }
}
</script>

<style scoped>

</style>
