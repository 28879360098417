<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="CalendarIcon"
          size="21"
      />
      <strong>
        Naptár
      </strong>
    </template>
    <div>
      <calendars
          ref="calendars"
          :can-create-reservation="true"
      ></calendars>
    </div>
  </b-tab>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BTab,
} from 'bootstrap-vue'
import Calendars from "@/views/pages/_components/_reservationDashboard/Calendars.vue";

export default {
  components: {
    Calendars,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BTab,
  },
  props: {
    fishingSpotId: {
      required: true
    }
  },
  created(){
    this.initData()
  },
  methods: {
    initData(){
      this.$store.dispatch('fetchReservationFishingSpot', this.fishingSpotId).then(response => {
        this.$refs.calendars.refreshData(response.data.entity.id, response.data.entity.name, null)
      })
    }
  }
}
</script>
