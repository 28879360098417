var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Szerkesztés"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Fotóalbum"}},[_c('validation-provider',{attrs:{"name":"fotóalbum","rules":"required","vid":"gallery"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.galleryOptions},model:{value:(_vm.formData.gallery),callback:function ($$v) {_vm.$set(_vm.formData, "gallery", $$v)},expression:"formData.gallery"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Árazás típus"}},[_c('validation-provider',{attrs:{"name":"árazás típus","rules":"required","vid":"pricingType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.pricingTypeOptions},model:{value:(_vm.formData.pricingType),callback:function ($$v) {_vm.$set(_vm.formData, "pricingType", $$v)},expression:"formData.pricingType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Rekordlista megfeleltetés"}},[_c('validation-provider',{attrs:{"name":"rekordlista megfeleltetés","rules":"required","vid":"location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.sortedLocationOptions},model:{value:(_vm.formData.location),callback:function ($$v) {_vm.$set(_vm.formData, "location", $$v)},expression:"formData.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Név"}},[_c('validation-provider',{attrs:{"name":"név","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kód"}},[_c('validation-provider',{attrs:{"name":"kód","rules":"required","vid":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},on:{"input":_vm.refreshMapMarker},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Minimálisan fizetendő díj","description":"A horgászhelyre történő foglalás esetén ennyit akkor is ki kell fizetni, ha paraméterek szerint számolt díj ennél kevesebb"}},[_c('validation-provider',{attrs:{"name":"minimálisan fizetendő díj","rules":"required","vid":"minimumFee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.minimumFee),callback:function ($$v) {_vm.$set(_vm.formData, "minimumFee", $$v)},expression:"formData.minimumFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Min. felnőtt horgász száma","description":"A hely foglalásához szükséges minimális felnőtt horgászok száma"}},[_c('validation-provider',{attrs:{"name":"min. felnőtt horgász száma","rules":"required","vid":"minAdult"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.minAdult),callback:function ($$v) {_vm.$set(_vm.formData, "minAdult", $$v)},expression:"formData.minAdult"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Min. felnőtt és ifi horgászok száma","description":"A hely foglalásához szükséges minimális felnőtt és ifi horgászok együttes száma (felnőtt+ifi)"}},[_c('validation-provider',{attrs:{"name":"min. felnőtt és ifi horgászok száma","rules":"required","vid":"minAdultYoungster"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.minAdultYoungster),callback:function ($$v) {_vm.$set(_vm.formData, "minAdultYoungster", $$v)},expression:"formData.minAdultYoungster"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Max. felnőtt és ifi horgászok száma","description":"A hely kapacitásának megfelelő, engedélyezhető maximális horgászlétszám (felnőtt+ifi)"}},[_c('validation-provider',{attrs:{"name":"max. felnőtt és ifi horgászok száma","rules":"required","vid":"maxAdultYoungster"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.maxAdultYoungster),callback:function ($$v) {_vm.$set(_vm.formData, "maxAdultYoungster", $$v)},expression:"formData.maxAdultYoungster"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Engedélyezhető egyéb létszám","description":"Engedélyezhető egyéb létszám (gyermek + kísérő)"}},[_c('validation-provider',{attrs:{"name":"engedélyezhető egyéb létszám","rules":"required","vid":"maxChildAttendant"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.maxChildAttendant),callback:function ($$v) {_vm.$set(_vm.formData, "maxChildAttendant", $$v)},expression:"formData.maxChildAttendant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Egyéb információ","description":"Egyéb információ (infobuborék)"}},[_c('validation-provider',{attrs:{"name":"egyéb információ","vid":"moreInfo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.moreInfo),callback:function ($$v) {_vm.$set(_vm.formData, "moreInfo", $$v)},expression:"formData.moreInfo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Megjegyzés"}},[_c('validation-provider',{attrs:{"name":"megjegyzés","vid":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.comment),callback:function ($$v) {_vm.$set(_vm.formData, "comment", $$v)},expression:"formData.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"csomagszállításhoz szilárd burkolatú bejáratú út biztosított","vid":"hasPavedRoad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasPavedRoad),callback:function ($$v) {_vm.$set(_vm.formData, "hasPavedRoad", $$v)},expression:"formData.hasPavedRoad"}},[_vm._v(" Csomagszállításhoz szilárd burkolatú bejáratú út biztosított ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"külön fizetendő parkolási díj","vid":"hasParkingFee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasParkingFee),callback:function ($$v) {_vm.$set(_vm.formData, "hasParkingFee", $$v)},expression:"formData.hasParkingFee"}},[_vm._v(" Külön fizetendő parkolási díj ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"H/M zuhanyzási lehetőség, vízöblítéses WC biztosított","vid":"hasShower"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasShower),callback:function ($$v) {_vm.$set(_vm.formData, "hasShower", $$v)},expression:"formData.hasShower"}},[_vm._v(" H/M zuhanyzási lehetőség, vízöblítéses WC biztosított ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"kiépített esőbeálló asztallal és padokkal (filagória) biztosított","vid":"hasPavilion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasPavilion),callback:function ($$v) {_vm.$set(_vm.formData, "hasPavilion", $$v)},expression:"formData.hasPavilion"}},[_vm._v(" Kiépített esőbeálló asztallal és padokkal (filagória) biztosított ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"kiépített tűzrakó hely biztosított","vid":"hasFireplace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasFireplace),callback:function ($$v) {_vm.$set(_vm.formData, "hasFireplace", $$v)},expression:"formData.hasFireplace"}},[_vm._v(" Kiépített tűzrakó hely biztosított ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"közvilágítás biztosított","vid":"hasStreetlight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasStreetlight),callback:function ($$v) {_vm.$set(_vm.formData, "hasStreetlight", $$v)},expression:"formData.hasStreetlight"}},[_vm._v(" Közvilágítás biztosított ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Áramfelvételi lehetőség külön térítés mellett","vid":"hasPowerConsumption"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hasPowerConsumption),callback:function ($$v) {_vm.$set(_vm.formData, "hasPowerConsumption", $$v)},expression:"formData.hasPowerConsumption"}},[_vm._v(" Áramfelvételi lehetőség külön térítés mellett ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"hagyományos horgászati módszer választható","vid":"canUseGeneralFishingMethod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.canUseGeneralFishingMethod),callback:function ($$v) {_vm.$set(_vm.formData, "canUseGeneralFishingMethod", $$v)},expression:"formData.canUseGeneralFishingMethod"}},[_vm._v(" Hagyományos horgászati módszer választható ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"bojlis módszernél csónakhasználat térítésmentes lehetősége","vid":"canUseBoat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.canUseBoat),callback:function ($$v) {_vm.$set(_vm.formData, "canUseBoat", $$v)},expression:"formData.canUseBoat"}},[_vm._v(" Bojlis módszernél csónakhasználat térítésmentes lehetősége ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"lakókocsi, lakóbusz a hely közelében elhelyezhető","vid":"canUseCaravan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.canUseCaravan),callback:function ($$v) {_vm.$set(_vm.formData, "canUseCaravan", $$v)},expression:"formData.canUseCaravan"}},[_vm._v(" Lakókocsi, lakóbusz a hely közelében elhelyezhető ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"teljes sátor használható","vid":"canUseTentFull"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.canUseTentFull),callback:function ($$v) {_vm.$set(_vm.formData, "canUseTentFull", $$v)},expression:"formData.canUseTentFull"}},[_vm._v(" Teljes sátor használható ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"csak félsátor használata engedélyezett","vid":"canUseTentHalf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.canUseTentHalf),callback:function ($$v) {_vm.$set(_vm.formData, "canUseTentHalf", $$v)},expression:"formData.canUseTentHalf"}},[_vm._v(" Csak félsátor használata engedélyezett ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"eseti, rejtett horgászhely","vid":"isHidden"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.isHidden),callback:function ($$v) {_vm.$set(_vm.formData, "isHidden", $$v)},expression:"formData.isHidden"}},[_vm._v(" Eseti, rejtett horgászhely ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.tabsLoading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-id":parseInt(this.$route.params.id),"markers":_vm.markers},on:{"refresh-tabs-data":_vm.refreshTabsData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }